<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex w-100 px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Registros</h3>
              <div class="ml-auto" style='gap: .4rem;'>
                <!-- <vs-button v-if='cantidadDatos' @click="$router.push('CrearCuenta')">Crear Cuenta</vs-button> -->
                <vs-button @click="$router.push('CrearRegistros')" >Registrar Movimiento</vs-button>
              </div>
            </div>
          </template>
          <div class='p-3'>

            <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns">
              <template v-slot:importe="{ row }">
                {{formatearNumero(row.importe)}}
              </template>
              <template v-slot:ID="{ row }">
                <div class="d-flex justify-content-center" style="gap: .5rem;">
                  <vs-button icon warn v-b-tooltip.hover title='evidencias' @click="mostrarModalEvidencias(row.ID)">
                    <i class="fa-solid fa-images"></i>
                  </vs-button>
                  <vs-button icon success v-b-tooltip.hover title='ver'
                             @click="$router.push(`DetallesRegistro/${row.ID}`)">
                    <i class="fa-solid fa-eye"></i>
                  </vs-button>
                  <vs-button icon title='Editar' v-b-tooltip.hover
                             @click="$router.push(`EditarRegistro/${row.ID}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button danger icon v-b-tooltip.hover title='Eliminar'
                             @click.prevent='deleteRegistro(row.ID)'>
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                </div>
              </template>
            </DataTableLotes>
          </div>
        </card>
      </b-col>
    </b-row>
    <b-modal id="modal-evidencias" title="Lista evidencias" size="lg" hide-footer>
      <table id="datatable_nomina" class="table table-bordered table-responsive-md table-striped text-center">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Fecha</th>
            <th>opciones</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tablaEvidencia" :key="item.id">
            <!-- <td>
              {{ $moment.tz(item.fechaInicio, "America/Costa_Rica").format("DD-MM-YYYY") + " / " + $moment.tz(item.fechaFin, "America/Costa_Rica").format("DD-MM-YYYY") }}
            </td> -->
            <td>{{ item.nombreArchivo }}</td>
            <!-- <td>{{ item.totalCostos != null ? item.totalCostos.toLocaleString("es-CO", { style: "currency", currency: "COP" }) : "$ 0.00" }}</td> -->
            <td>{{ item.fecha }}</td>
            <!-- <td>
              {{ $moment.tz(item.fechaInicio, "America/Costa_Rica").format("DD-MM-YYYY") + " / " + $moment.tz(item.fechaFin, "America/Costa_Rica").format("DD-MM-YYYY") }}
            </td> -->
            <td>
              <div class="d-flex justify-content-center px-0 mx-0">
                <vs-button success icon class="px-0" @click="mostrarEvidencia(item)"  ><i class="fas fa-solid fa-eye "></i>
                </vs-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </b-container>
</template>


<script>
import Swal from 'sweetalert2';
import { core } from '../../../../../../config/pluginInit';
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";
// import { debounce } from 'lodash/debounce';

export default {
  components: {DataTableLotes},
  data: () => ({
    tablaEvidencia: [],
    columns: [
      {label: "Fecha", key: "fechaRegistro", sortable: true},
      {label: "Descripcion", key: "descripcion", sortable: true},
      {label: "Referencia", key: "referencia", sortable: true},
      {label: "Importe", key: "importe", sortable: true},
      {label: "Opciones", key: "ID", sortable: false},
    ]
  }),
  watch: {
    // search: {
    //   handler: debounce(function () {
    //     this.getData();
    //   }, 700),
    //   immediate: false
    // },
    search() {
      this.getData();
    },
    max() {
      this.getData();
    },
    page() {
      this.getData();
    },
    totalpagues() {
      this.getData();
    },
    columna() {
      this.getData();
    },
    ascendente() {
      this.getData();
    }
  },
  async created() {
    const Persona = JSON.parse(localStorage.getItem('credenciales'));
    this.PersonaId = Persona.personaId;

    core.index()
    this.getData();
  },
  methods: {
    mostrarEvidencia(item) {
      const url = 'https://docs.google.com/gview?url='+item.url + '/' + item.nombreArchivo+'&embedded=true';
      window.open(url, "_blank");
    },
    async mostrarModalEvidencias(id) {
      this.tablaEvidencia = await this.$store.dispatch("hl_get", {
        path: `RegistroContableEvidencias/ListRegistroContableEvidencias/${id}`
      });

      this.$bvModal.show('modal-evidencias')
    },
    async deleteRegistro(id) {
      await Swal.fire({
        title: '¿Estás seguro que deseas eliminar este Movimiento?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrarlo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("hl_delete", {
              path: `DEMovimientos/EliminarMovimiento/${id}/persona/${this.PersonaId}`,
            }).then((res) => {
              if (res.respuesta) {
                Swal.fire("Listo!", "Movimiento eliminado correctamente.", "success");
                this.$refs.dataTableLotes.$emit("actualizar");
              } else {
                Swal.fire("Error", res.mensaje, "error");
              }
            });
          } catch (error) {
            await Swal.fire("Error", "Hubo un problema al eliminar el Movimiento: "+error, "error");
          }
        }
      });
    },
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.PersonaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      await Promise.all([
        this.$store.dispatch("hl_get", {
          path: `DEMovimientos/ConsultarCantidadMovimientos/${this.PersonaId}?filtro=${filtro}`
        }),
        this.$store.dispatch("hl_post", {
          path: 'DEMovimientos/MovimientosEmpresa/' + this.PersonaId,
          data: params,
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
          data.forEach(Element => {
            Element.fechaRegistro = this.$moment.tz(Element.fechaRegistro, "America/Bogota").format("MM-DD-YYYY")
          })
        }
      });
      return {total, data};
    },
    formatearNumero(numero) {
      try {
        // Configurar opciones de formato
        const opciones = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        // Formatear el número sin símbolo
        const formato = new Intl.NumberFormat('es-CO', opciones);
        const numeroFormateado = formato.format(numero);

        // Agregar el símbolo manualmente
        return `COP ${numeroFormateado}`;

      } catch (error) {
        console.error("Error al formatear el número:", error.message);
        return numero; // Devolver el número original en caso de error
      }
    }
  },
}
</script>

<style>
.vs-input {
  width: 100%;
}

.header-title {
  width: 100% !important;
}
</style>